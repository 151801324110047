export const COUNTIES_EMBEDDED_REPORTS = {
  TotalBudgetForAllCounties: {
    reportId: "da02f181-f548-4508-bbf1-9743fbbf7cab",
  },

  TopTenCountiesByRevenueReport: {
    reportId: "f632ca95-c2f8-49b5-acc0-85f5f84ab834",
  },

  TopTenCountiesByExpenditureReport: {
    reportId: "eb2662a6-5086-4c03-921a-65cc1287d8bd",
  },

  AllCountyExpendituresByCategoryReport: {
    reportId: "2d996c3c-0ffb-4d2c-91cb-46e1391a9d61",
  },

  AllCountyRevenuesByCategoryReport: {
    reportId: "37f87b5b-3233-453c-b271-8103ee2cf164",
  },

  ExpenditurePerCapitaReport: {
    reportId: "6d6e5c77-778a-4cce-8920-560aee44bed3",
  },

  PropertyTaxPercentOfBudgetReport: {
    reportId: "bbd3bddc-83b1-475b-8cde-9ea0318ccd46",
  },

  AllCountyFundBalancesReport: {
    reportId: "d94bc69c-ee50-496c-a8f2-9d67df12c22d",
  },
  CountySalariesReport: {
    reportId: "37a9e066-c119-40c8-b64a-99dd375588de",
  },
};

export const COUNTY_SPECIFIC_EMBEDDED_REPORTS = {
  TotalCountyBudget: {
    reportId: "86d8d688-1185-48e6-a4c7-54bb3c014148",
    visualName: "ea2d66eb649938006c6e",
  },

  CountyBudgetDetails: {
    reportId: "f82c1f23-5edd-4947-a60e-89eee860bf04",
    visualName: "ea2d66eb649938006c6e",
  },
  CountyExpendituresByCategory: {
    reportId: "283945a5-683c-42c4-a2ac-1ebe700d8419",
    visualName: "9455e30a4b60415c3640",
  },
  ExpenditurePerCapita: {
    reportId: "9382447f-1394-46b0-ab05-86884c2afc21",
    visualName: "e7750c345b81fab4f5be",
  },
  CountyRevenuesByCategory: {
    reportId: "fe9515d5-2289-428d-8c2b-50a5a759357b",
    visualName: "c3f12e7304d038572c46",
  },
  TopCountyFundBalancesByCategory: {
    reportId: "d47f8112-6729-4170-86b7-39fb85787607",
    visualName: "d6ecc97b00db902060b0",
  },
  AllCountyFundBalancesByClassification: {
    reportId: "7b0d379b-06db-4f36-afcd-e572bd58f264",
    visualName: "a131e530810e6a859b5e",
  },
  CountyFundBalanceByPercentOfBudget: {
    reportId: "217555cb-ca40-4458-aef6-1ca0568d5844",
    visualName: "7735b1318403809c453d",
  },
  ExpenditureComparison: {
    reportId: "fb2ef8e2-acbf-46ca-8f86-abd9526ace0d",
    visualName: "ea2d66eb649938006c6e",
  },
  RevenueComparison: {
    reportId: "0de18c70-ff89-4cee-b533-4660d52ed605",
    visualName: "ea2d66eb649938006c6e",
  },
  CountySalariesReport: {
    reportId: "7235c4a7-a851-4312-83dd-6c05b784b526",
    visualName: "69810ecf6867b759477e",
  },
};

export const EDUCATION_EMBEDDED_REPORTS = {
  EducationYearOverYearFinancials: {
    reportId: "a3dc5cb1-870d-400a-8657-ecb0427b2ed2",
  },
  EducationSalaries: {
    reportId: "57c745e7-f891-4efa-8bde-302963b78778",
  },
};

export const CITY_SPECIFIC_REPORTS = {
  CityBudgetDetails: {
    reportId: "0c6b4c63-6d04-4e99-a0bb-3c122acd0a3f",
    visualName: "ea2d66eb649938006c6e",
  },
  TopRevenueDetails: {
    reportId: "e2f5c6c4-6749-4f0b-8896-a7d36f2d946a",
    visualName: "c3f12e7304d038572c46",
  },
  TopExpenditureDetails: {
    reportId: "b71dcb76-9b78-40e5-a1e9-098796ea411f",
    visualName: "9455e30a4b60415c3640",
  },
  CitySalariesDetails: {
    reportId: "381a613f-af24-4ae6-b3e8-c7d9983f61b4",
    visualName: "69810ecf6867b759477e",
  },
  CityFundBalancesDetails: {
    reportId: "cfcce2e0-96b1-4b18-88bc-0d9827ff42e7",
    visualName: "a131e530810e6a859b5e",
  },
};

export const CITY_REPORTS = {
  TotalBudgetForCities: {
    reportId: "9b5441a5-a71c-4598-86ea-ae546d7ee61f",
  },
  TotalRevenuesByCategory: {
    reportId: "0b7f2c0d-20d5-456e-8daa-50aba48a76a9",
  },
  TotalExpendituresByCategory: {
    reportId: "f60d5fea-0209-497e-bbf9-da694fd2d7f6",
  },
  CitySalaries: {
    reportId: "381a613f-af24-4ae6-b3e8-c7d9983f61b4",
  },
  FundBalanceClassification: {
    reportId: "99a6854b-e613-4286-a647-428eb310fa03",
  },
};
